import type { ShallowResponse } from 'types/responses'
import type { Nullable } from 'types/helpers'

import { PricingPlan } from 'types/pricing-plans'
import { PayerData } from 'types/payer-data'
import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

interface SalesTeamMember {
  id: number
  avatar: string
  email: string
  fullName: string
  termsAccepted: number
  userProfile: any
  phone: string
}

export interface Deal {
  id: number
  commissionRate: string
  couponId: Nullable<number>
  incentivePlanId: number
  orderId: Nullable<number>
  payerData: PayerData
  payerEmail: string
  payerFullName: string
  payerId: Nullable<string>
  price: string
  pricingPlanId: number
  pricingPlan: PricingPlan
  productId: number
  product: any
  productSlug: string
  productIncentiveId: number
  productName: string
  salesTeamId: number
  salesTeamMember: SalesTeamMember
  salesTeamMemberId: number
  selectedPayMethod: string
  sellerId: number
  state: string
  token: Nullable<string>
  ownerData: any
  showCustomFields: boolean
  showContactInfo: boolean
  optInAnswers: any[]
  coupon: any
  idForSeller: number
  paymentLink: string
  previewLink: string
  orderToken?: string
}

const BASE_URL = (username) => `/shop/${username}/deals`

export const createDealsApi = ({ GET, POST }: ApiClientV2) => ({
  fetchItem: (id: string, data: { username: string }) => GET(`${BASE_URL(data.username)}/${id}`, data),
  createOrder: (username, data) =>
    POST<ShallowResponse<{ redirectLink: string; clientSecret: string }>>(`${BASE_URL(username)}`, data),
  acceptDeal: (username, { id, ...data }) => POST<ShallowResponse<Deal>>(`${BASE_URL(username)}/${id}/accept`, data),
})

export type DealsApi = ReturnType<typeof createDealsApi>
