import { createUseStyles } from 'react-jss'

import { COLORS } from '@elo-kit/constants/general.constants'
import { PDF_FILE, DISPLAY_OPTION } from '@elo-kit/constants/contentPage.constants'
import { getBackgroundImageCss } from '@elo-kit/utils/themes.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

export const PDF_CONTAINER_MAX_HEIGHT = 500
export const PDF_TOOLBAR_HEIGHT = 52
export const PDF_PAGINATOR_HEIGHT = 24
export const PDF_PAGINATOR_WIDTH = 40
export const PROGRESS_BAR_HEIGHT = 4
export const PDF_MAX_BUTTON_SIZE = 30
export const PDF_MAX_TEXT_SIZE = 20
export const TOOLBAR_ITEMS_HEIGHT = PDF_TOOLBAR_HEIGHT - PROGRESS_BAR_HEIGHT

export const useReaderStyles = createUseStyles((theme = {}) => {
  const { eloGrey40, eloWhite, eloGrey70, eloOrange, eloGrey60, eloBlue, eloGrey90, eloBlack } = COLORS
  const toolbarFont = cleanupFonts(theme[PDF_FILE.toolbarFont]) || 'Montserrat Reg'
  const toolbarFontSize = theme[PDF_FILE.toolbarFontSize]
    ? `${theme[PDF_FILE.toolbarFontSize] > PDF_MAX_TEXT_SIZE ? PDF_MAX_TEXT_SIZE : theme[PDF_FILE.toolbarFontSize]}px`
    : '12px'

  return {
    pdfContainerRoot: {
      width: '100%',
      height: '100%',
      minHeight: `${PDF_CONTAINER_MAX_HEIGHT}px`,
    },
    pdfContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: `${PDF_CONTAINER_MAX_HEIGHT}px`,
      minHeight: `${PDF_CONTAINER_MAX_HEIGHT}px`,
      '&:fullscreen': {
        '& $documentWrapper': {
          maxHeight: 'initial !important',
          minHeight: 'initial !important',
        },
        '& $toolbarContainer': {
          position: 'absolute',
          bottom: 0,
          width: '100%',
        },
      },
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      backgroundImage: getBackgroundImageCss(theme[PDF_FILE.backgroundImage]),
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    backgroundOverlay: {
      backgroundColor: theme[PDF_FILE.backgroundColor] || eloGrey40,
      opacity: theme[PDF_FILE.transparency],
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    documentWrapper: {
      width: '100%',
      overflow: 'auto',
      zIndex: 1,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: `${PDF_CONTAINER_MAX_HEIGHT - PDF_TOOLBAR_HEIGHT}px`,
      minHeight: `${PDF_CONTAINER_MAX_HEIGHT - PDF_TOOLBAR_HEIGHT}px`,
      userSelect: 'none',
      '& .loading-spinner': {
        margin: 'auto',
      },
    },
    toolbarContainer: {
      display: 'flex',
      backgroundColor: theme[PDF_FILE.toolbarBackgroundColor] || eloWhite,
      boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.15)',
      height: `${PDF_TOOLBAR_HEIGHT}px`,
      zIndex: 1,
      '& .fas': {
        fontSize: theme[PDF_FILE.toolbarButtonSize]
          ? `${
              theme[PDF_FILE.toolbarButtonSize] > PDF_MAX_BUTTON_SIZE
                ? PDF_MAX_BUTTON_SIZE
                : theme[PDF_FILE.toolbarButtonSize]
            }px`
          : '16px',
        color: theme[PDF_FILE.buttonColor] || eloGrey60,
        '@media (max-width: 700px)': {
          fontSize: '24px',
        },
      },
      '& .error': {
        '& $progressBarContainer': {
          opacity: 0,
        },
      },
    },
    toolbarContainerItems: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 30px',
      width: '100%',
      lineHeight: `${TOOLBAR_ITEMS_HEIGHT}px`,
      height: `${TOOLBAR_ITEMS_HEIGHT}px`,
      marginTop: `${PROGRESS_BAR_HEIGHT}px`,
    },
    toolbarLeft: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '40%',
      overflow: 'hidden',
      paddingRight: '40px',
      zIndex: 2,
      '@media (max-width: 700px)': {
        width: '20%',
      },
    },
    toolbarCenter: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      position: 'absolute',
      marginLeft: '-30px',
    },
    toolbarRight: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '20%',
      justifyContent: 'flex-end',
      zIndex: 2,
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      margin: '0 14px 0',
      zIndex: 2,
      '&.disabled': {
        cursor: 'initial',
        pointerEvents: 'none',
      },
      '&:hover': {
        opacity: 0.8,
      },
    },
    downloadAction: {
      width: '100%',
      alignItems: 'center',
      '& i': {
        display: theme[PDF_FILE.downloadFile] === DISPLAY_OPTION.display ? 'flex' : 'none',
      },
      '& $actionButton': {
        margin: 0,
      },
    },
    fullscreenContainer: {
      '& $actionButton': {
        margin: '2px 0 0 23px',
      },
    },
    zoomContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& $actionButton': {
        margin: '0 7px 0',
      },
      '@media (max-width: 700px)': {
        display: 'none',
      },
    },
    filenameContainer: {
      ...getFontStyles(theme[PDF_FILE.toolbarFontStyle]),
      color: theme[PDF_FILE.toolbarColor] === eloGrey60 ? eloGrey90 : theme[PDF_FILE.toolbarColor],
      fontFamily: toolbarFont,
      fontSize: toolbarFontSize,
      letterSpacing: 0,
      userSelect: 'none',
      height: `${TOOLBAR_ITEMS_HEIGHT}px`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: '-4px',
      marginRight: '7px',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 20px)',
      '@media (max-width: 700px)': {
        display: 'none',
      },
    },
    paginationContainer: {
      display: 'flex',
    },
    paginationInfo: {
      ...getFontStyles(theme[PDF_FILE.toolbarFontStyle]),
      color: theme[PDF_FILE.toolbarColor] || eloGrey70,
      fontSize: toolbarFontSize,
      fontFamily: toolbarFont,
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      '& .field': {
        marginBottom: 0,
      },
      '& .number-field__control': {
        padding: '0px 5px',
        textAlign: 'right',
        height: `${PDF_PAGINATOR_HEIGHT}px`,
        lineHeight: `${PDF_PAGINATOR_HEIGHT + 1}px`,
        width: `${PDF_PAGINATOR_WIDTH}px`,
      },
      '& .number-field--disabled': {
        opacity: 1,
      },
      '& span > span': {
        ...getFontStyles(theme[PDF_FILE.toolbarFontStyle]),
        color: theme[PDF_FILE.toolbarColor] || eloGrey70,
        fontSize: toolbarFontSize,
        fontFamily: toolbarFont,
      },
    },
    paginationInfoMsg: {
      lineHeight: `${TOOLBAR_ITEMS_HEIGHT + 1}px`,
      marginLeft: '7px',
      whiteSpace: 'nowrap',
      '& > span': {
        fontSize: toolbarFontSize,
        color: theme[PDF_FILE.toolbarColor] === eloGrey70 ? eloBlack : theme[PDF_FILE.toolbarColor],
        fontWeight: 500,
        marginLeft: '3px',
      },
    },
    progressBarContainer: {
      display: 'flex',
      backgroundColor: theme[PDF_FILE.progressBackgroundColor] || eloBlue,
      height: '4px',
      width: '100%',
      position: 'absolute',
      opacity: 0.2,
    },
    progressBarItem: {
      height: '4px',
      position: 'absolute',
      backgroundColor: theme[PDF_FILE.progressBackgroundColor] || eloBlue,
    },
    noDateMessage: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .fas': {
        fontSize: '30px',
        color: eloOrange,
      },
      '& .error-header': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .error-header-text': {
        margin: '3px 0 0 8px',
        fontFamily: 'Montserrat Reg',
        fontSize: '18px',
        fontWeight: 500,
      },
      '& .error-content': {
        fontSize: '13px',
        fontFamily: 'Montserrat Reg',
        margin: '17px 0',
        maxWidth: '400px',
        textAlign: 'center',
        color: eloGrey70,
      },
    },
    pdfDocument: {
      margin: 'auto',
    },
    pdfPage: {
      '& .react-pdf__Page__canvas': {
        border: '10px solid transparent',
        // eslint-disable-next-line
        borderImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAA1ElEQVQ4jbWUWw6EIAxFy2NFs/8NzR4UJhpqLsdi5mOmSSMUOfYWqv3S0gMr4XlYH/64gZa/gN3ANYA7KAXALt4ktoQ5MI9YxqaG8bWmsIysMuT6piSQCa4whZThCu8CM4zP9YJaKci9jicPq3NcBWYoPMGUlhG7ivtkB+gVyFY75wXghOvh8t5mto1Mdim6e+MBqH6XsY+YAwjpq3vGF7weTWQptLEDVCZvPTMl5JZZsdh47FHW6qFMyvLYqjcnmdFfY9Xk/KDOlzCusX2mi/ofM7MPkzBcSp4Q1/wAAAAASUVORK5CYII=') 9 9 repeat`,
      },
    },
  }
})

export default useReaderStyles
