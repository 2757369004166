import { createUseStyles } from 'react-jss'

import { cleanupFonts } from 'utils/fonts.utils'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { getAlignment } from '@elo-kit/utils/themes.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'
import { getPadding } from '@elo-kit//utils/contentBlock.utils'
import { TEXT, DEFAULT_BANNER_PADDING, BANNER, BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { COLORS, POSITIONS, ZERO_DATA, PADDING_UNITS } from '@elo-kit/constants/general.constants'

const { eloBlack, eloBlue, eloWhite } = COLORS

export const useBannerStyles = createUseStyles((theme) => {
  const defaultPadding = theme.paddingUnits === PADDING_UNITS.percentage ? ZERO_DATA : DEFAULT_BANNER_PADDING

  return {
    bannerContainer: {
      position: 'relative',
      height: '100%',
    },
    banner: {
      position: 'relative',
      backgroundColor: '#efedef',
      maxWidth: '100%',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: theme[BANNER.backgroundImage] ? '600px' : '450px',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: getPadding(theme[TEXT.paddingTop], defaultPadding, theme.paddingUnits),
      paddingBottom: getPadding(theme[TEXT.paddingBottom], defaultPadding, theme.paddingUnits),
      paddingRight: getPadding(theme[TEXT.paddingRight], defaultPadding, theme.paddingUnits),
      paddingLeft: getPadding(theme[TEXT.paddingLeft], defaultPadding, theme.paddingUnits),

      '@media (max-width: 991px)': {
        height: '400px',
      },
      '@container (max-width: 991px)': {
        height: '400px',
      },
      '@media (max-width: 500px)': {
        height: '200px',
      },
      '@container (max-width: 500px)': {
        height: '200px',
      },
    },
    bannerInfoContainer: {
      margin: '0 auto',
      maxWidth: '1140px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    noImageIcon: {
      fontSize: '200px',
      color: eloWhite,
      position: 'absolute',
      left: 'calc(50% - 100px)',
      top: 'calc(50% - 100px)',
    },
    title: {
      fontSize: theme[BANNER.mainSize] ? `${theme[BANNER.mainSize]}px` : '60px',
      fontFamily: cleanupFonts(theme[BANNER.mainFont]) || 'Montserrat Medium',
      ...getFontStyles(theme[BANNER.mainStyle]),
      color: theme[BANNER.mainColor] || eloBlack,
      width: `${theme[BANNER.width] || 100}%`,
      maxWidth: `${theme[BANNER.maxWidth] || 500}px`,
      lineHeight: 1,
      paddingTop: '20px',
      paddingBottom: '20px',
      wordBreak: 'keep-all',
      overflowWrap: 'normal',
      alignSelf: getAlignment(theme.textAlignment || POSITIONS.left),
      textAlign: theme.textAlignment === 'center' ? 'center' : 'none',
      '@media (max-width: 576px)': {
        fontSize: theme[BANNER.mainSize] > 40 ? 40 : theme[BANNER.mainSize],
      },
      '@container (max-width: 576px)': {
        fontSize: theme[BANNER.mainSize] > 40 ? 40 : theme[BANNER.mainSize],
      },
    },
    subTitle: {
      fontSize: theme[BANNER.secondSize] ? `${theme[BANNER.secondSize]}px` : '16px',
      fontFamily: cleanupFonts(theme[BANNER.secondFont]) || 'Montserrat Reg',
      ...getFontStyles(theme[BANNER.secondStyle]),
      color: theme[BANNER.secondColor] || eloBlack,
      width: `${theme[BANNER.width] || 100}%`,
      maxWidth: `${theme[BANNER.maxWidth] || 500}px`,
      lineHeight: 1.5,
      paddingBottom: '38px',
      wordBreak: 'break-word',
      alignSelf: getAlignment(theme.textAlignment || POSITIONS.left),
      textAlign: theme.textAlignment === 'center' ? 'center' : 'none',
    },
    button: {
      background: theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme[BANNER.btnColor] || eloBlue,
      color: theme[BANNER.btnTextColor] || eloWhite,
      border: `2px solid ${theme.buttonColor || '#1e84d7'}`,
      borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
      ...getFontStyles(theme.buttonStyle || 'bold'),
      fontSize: `${theme.buttonSize || 15}px`,
      cursor: 'pointer',
      wordBreak: 'break-word',
      textAlign: 'center',
      lineHeight: 1,
      minHeight: '50px',
      width: '240px',
      letterSpacing: 1,
      marginBottom: 20,
      padding: 5,
      alignSelf: getAlignment(theme.horizontalAlignment || POSITIONS.left),
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        opacity: 0.7,
      },
    },

    ...buttonAnimations(theme),
  }
})
