import React, { useEffect, useState, useRef } from 'react'

import { useIsVisible } from '../lazy-image/use-is-visible'

interface BackgroundProps {
  block: {
    content?: {
      backgroundColor: string
      transparency: string
      backgroundTransparency?: string
      backgroundImage?: string
    }
    cover: {
      file: {
        original: string
        s640: string
      }
    }
  }
  style?: object
  wrapperStyles?: object
  className?: string
  wrapperClassName?: string
  position?: string
  isResizable?: boolean
}

const LazyBackgroundImage: React.FC<BackgroundProps> = ({
  block,
  wrapperStyles,
  className,
  wrapperClassName,
  position,
  isResizable,
}) => {
  const fullQualitySrc = block?.cover?.file?.original || block?.content?.backgroundImage
  const lowQualitySrc = block?.cover?.file?.s640 || block?.content?.backgroundImage

  const ref = useRef<HTMLImageElement>()
  const wrapperRef = useRef<HTMLDivElement>()
  const imgObserver = useRef<any>()
  const isVisible = useIsVisible(ref)
  const [src, setSrc] = useState(lowQualitySrc)
  const [aspectRatio, setAspectRatio] = useState(1)
  const [size, setSize] = useState<{
    width?: number | string
    height?: number | string
    maxWidth?: number | string
    maxHeight?: number | string
    minWidth?: number | string
    minHeight?: number | string
  }>({ width: '100%', height: '100%', minWidth: '100%', minHeight: '100%' })

  const handleLoad = (e) => {
    const target = e.target as HTMLImageElement
    const imageRatio = target?.naturalWidth / target?.naturalHeight
    const containerRatio = ref.current?.parentElement?.clientWidth / ref.current?.parentElement?.clientHeight
    const w = ref.current?.parentElement?.clientWidth
    const h = ref.current?.parentElement?.clientHeight
    setSize(
      imageRatio > containerRatio
        ? { width: h * imageRatio, height: h, maxWidth: 'initial', maxHeight: 'initial' }
        : { width: w, height: w / imageRatio, maxWidth: 'initial', maxHeight: 'initial' }
    )

    setAspectRatio(imageRatio)
  }

  const setFullLink = () => {
    const img = new Image()
    img.onload = (e) => {
      setSrc(fullQualitySrc)
      handleLoad(e)
    }
    img.src = fullQualitySrc
  }

  useEffect(() => {
    if (isVisible && src !== fullQualitySrc) {
      setFullLink()
    }
    if (!fullQualitySrc) {
      setSrc('')
    }
  }, [isVisible, fullQualitySrc])

  const handleResize = () => {
    handleLoad({ target: ref.current })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    if (isResizable) {
      imgObserver.current = new ResizeObserver((entries) => {
        entries.forEach(() => {
          handleResize()
        })
      })

      imgObserver.current?.observe(wrapperRef.current)
    }

    return () => {
      window.removeEventListener('resize', handleResize)

      if (isResizable) {
        imgObserver.current?.disconnect()
      }
    }
  }, [])

  return (
    <div
      ref={wrapperRef}
      className={wrapperClassName}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        ...wrapperStyles,
      }}
    >
      {!!fullQualitySrc && (
        <img
          // @ts-ignore
          ref={ref}
          className={className}
          loading='lazy'
          alt=''
          style={{
            filter: src !== fullQualitySrc ? 'blur(10px)' : 'none',
            transition: 'filter 0.3s linear',
            aspectRatio: `${aspectRatio}`,
            position: 'absolute',
            left: '50%',
            top: position === 'top' ? '0' : '50%',
            transform: position === 'top' ? 'translate3d(-50%, 0, 0)' : 'translate3d(-50%, -50%, 0)',
            ...size,
          }}
          src={src}
          onLoad={handleLoad}
        />
      )}

      <div
        className='overlay'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: block?.content?.backgroundColor || 'unset',
          opacity: block?.content?.backgroundTransparency || block?.content?.transparency,
        }}
      />
    </div>
  )
}

export { LazyBackgroundImage }
