import { action, override, computed, observable, makeObservable } from 'mobx'

import {
  CARD_NUMBER,
  PAYMENT_FORMS,
  PAYMENT_METHODS,
  PAYMENT_PROVIDERS,
} from 'constants/paymentSettingShared.constants'
import { DEFAULT_CURRENCY } from 'constants/currencies.constants'
import { SUCCESSFUL_ORDER_STATES } from 'constants/ordersShared.constants'
import { YES } from '@elo-kit/constants/general.constants'

import { arrayLast } from 'utils/array.utils'

import {
  convertPriceForStripe,
  handleIdealTransaction,
  handleP24Transaction,
  handleSepaError,
  handleSofortTransaction,
  handleStripeCardTransaction,
  handleStripeDigitalTransaction,
  handleTransactionResponse,
  handleStripeCardRecuringPayment,
  handleStripeSofortRecuringPayment,
} from '@elo-kit/utils/stripe.utils'
import { getPubKey } from 'utils/paymentSettingShared.utils'

import { notify } from 'libs/common/notify'
import { apiClient, getMP3dsBrowserMetaData } from 'utils/requests.utils'
import { isWindowEnv } from 'utils/env.utils'

import { Nullable } from 'types/helpers'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { OrdersApi, createOrdersApi } from 'shop/api/orders.api'
import { Details, Invoice, Order, Paymethods, Popups, Transfer } from 'shop/types/orders'
import { TicketUpgradeData } from 'types/upgrade-mappings'

import {
  Stripe,
  StripeElement,
  StripeElements,
  StripeExpressCheckoutElement,
  StripeElementsOptionsMode,
  StripePaymentParams,
  StripeP24BankElement,
  StripeIdealBankElement,
} from 'shop/types/stripe'
import { createKibanaApi, KibanaApi } from 'shop/api/kibana.api'

interface ChargeOrderData {
  pricingPlanId: number
  transfer?: {
    paypalFraudToken?: string
    browserInfo?: {
      colorDepth: number
      screenHeight: number
      screenWidth: number
      timeZoneOffset: number
      language: string
      javaEnabled: boolean
      javaScriptEnabled: boolean
      userAgent: string
    }
  }
}

export class OrdersStore extends SharedStore<Order> {
  storeName = 'OrdersStore'
  declare childApi: OrdersApi
  kibanaApi: KibanaApi
  root: ShopRootStore

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    this.childApi = createOrdersApi(root?.apiClient ?? apiClient)
    makeObservable(this)
    this.kibanaApi = createKibanaApi(root?.apiClient ?? apiClient)
    this.loading = true
  }

  @observable data: Details
  @observable selectedPlanId: Nullable<number> = null
  @observable paymethods: Paymethods
  @observable currentFunnelNode = {}
  @observable popups: Popups = {
    changePlan: false,
    changeMethod: false,
    changePlanAutopay: false,
    postsellPayment: false,
    autopaySecondStep: false,
    changeAttendeeDetails: false,
  }

  @observable stripePaymentParams: StripePaymentParams = {
    clientSecret: null,
    redirectionUrl: null,
    payerFullName: null,
    payerCountryCode: null,
    payerEmail: null,
  }
  @observable fraudSessionIdentifier = null

  @observable ticketUpgradeData: Nullable<TicketUpgradeData> = null

  @observable eventAttendees = null

  @computed get seller() {
    return this.root.sellerStore.item
  }

  @action setTokenAndFetchPS = async (
    token: string,
    data?: { expand: string[] },
    params?: { token?: string; username?: string },
    accessToken?: string
  ) => {
    this.data = {
      ...this.data,
      token,
      params: isWindowEnv() ? this.getAllUrlParams() : params,
    }

    return await this.fetchPSManage(data, params?.username, accessToken)
  }

  @action fetchEventAttendees = async (token: string, data: { username: string }) => {
    this.setLoading(true)
    const resp = await this.childApi.fetchEventAttendees(token, data)
    this.setLoading(false)
    this.eventAttendees = resp?.data
    return resp
  }

  @action fetchTicketUpgrade = async (token: string, data: { username: string }) => {
    this.setLoading(true)
    const resp = await this.childApi.fetchTicketUpgrade(token, data)
    this.setLoading(false)
    this.ticketUpgradeData = resp?.data
    return resp
  }

  @action togglePopup = (name: string) => {
    this.popups[name] = !this.popups[name]
  }

  @action setLoading = (value) => {
    this.loading = value
  }

  @action setStripePaymentParams = (params: StripePaymentParams) => {
    this.stripePaymentParams = params
  }

  @action updatePaymethodsData = (data: Paymethods) => {
    this.paymethods = data
  }

  @action onSelectPlan = (planId: number) => {
    this.selectedPlanId = planId
  }

  @action setFraudSessionIdentifier = (value) => {
    this.fraudSessionIdentifier = value
  }

  @action sendDealPaymentEmail = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.sendDealPaymentEmail(this.root.sellerStore.item.username, this.item.token)
    this.toggleLoading(false)
    return resp
  }

  @action sendDealBindingOfferEmail = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.sendDealBindingOfferEmail(this.root.sellerStore.item.username, this.item.token)
    this.toggleLoading(false)
    return resp
  }

  // we should use just payment session show
  @action fetchPSManage = async (reqData: { expand: string[] }, username?: string, accessToken?: string) => {
    const resp = await this.childApi.manageOrder(
      this.root.sellerStore.item.username || username,
      this.data.token,
      reqData,
      accessToken
    )
    const { data, success } = resp || {}

    if (success) {
      this.data = {
        ...this.data,
        ...data,
      }

      await this.root.sellerStore.setLockingByToken(this.data.protectionUrl)
    }

    this.setLoading(false)

    return resp
  }

  redirect = (redirectLink: string) => (window.location.href = redirectLink)

  @action chargePS = async (
    data?: { amount?: number; toPay?: string; transfer?: any },
    stripeClient?: Stripe,
    stripeElement?: StripeElements | StripeElement | StripeP24BankElement | StripeIdealBankElement
  ) => {
    const { amount, ...rest } = data || {}

    this.setLoading(true)

    const chargeOrderData = {
      ...rest,
    }

    const isCard = this.data.paymentForm === PAYMENT_FORMS.card
    const isMangoPay = this.data.moneyHolder.cardProvider === PAYMENT_PROVIDERS.mangoPay

    if (isCard && isMangoPay) {
      chargeOrderData.transfer = getMP3dsBrowserMetaData()
    }

    if (this.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.fraudSessionIdentifier,
      }

      chargeOrderData.transfer = {
        ...chargeOrderData.transfer,
        ...paypalFraudData,
      }
    }

    const resp = await this.childApi.chargeOrder(this.root.sellerStore.item.username, this.data.token, chargeOrderData)
    const { error, redirectLink, clientSecret, paymentMethod, success } = resp

    if (success) {
      const sofortProvider = this.data.provider || this.root.sellerStore.item.sofortProvider
      const shouldHandleStripe = redirectLink && clientSecret && stripeClient
      const isP24 = this.data.paymentForm === PAYMENT_FORMS.p24
      const isIdeal = this.data.paymentForm === PAYMENT_FORMS.ideal
      const isStripeCard = this.data.paymentForm === PAYMENT_FORMS.card
      const isStripeSofort =
        this.data.paymentForm === PAYMENT_FORMS.sofort &&
        (sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)
      const isDigitalPayment =
        this.data.paymentForm === PAYMENT_FORMS.applePay || this.data.paymentForm === PAYMENT_FORMS.googlePay

      if (shouldHandleStripe && isP24) {
        handleP24Transaction(stripeClient, stripeElement as StripeP24BankElement, redirectLink, clientSecret, {
          fullName: this.data.payer.fullName,
          email: this.data.payer.email,
          tosShowAndAccepted: false,
        })
      }

      if (shouldHandleStripe && isIdeal) {
        handleIdealTransaction(stripeClient, stripeElement as StripeIdealBankElement, redirectLink, clientSecret, {
          fullName: this.data.payer.fullName,
          email: this.data.payer.email,
        })
      }

      if (shouldHandleStripe && isStripeSofort) {
        handleSofortTransaction(stripeClient, redirectLink, clientSecret, {
          countryCode: this.data.payer.countryCode,
          billingDetails: this.getBillingDetails(),
        })
      }

      if (shouldHandleStripe && (isStripeCard || isDigitalPayment)) {
        if (paymentMethod) {
          handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret).then((result) =>
            handleTransactionResponse(
              result,
              () => this.redirect(redirectLink),
              () => this.setLoading(false)
            )
          )
        } else {
          this.setStripePaymentParams({
            redirectLink,
            clientSecret,
            payerFullName: this.data.payer.fullName,
            payerCountryCode: this.data.payer.countryCode || this.data.payer.country?.code,
            payerEmail: this.data.payer.email,
            amount,
          })
          this.setLoading(false)
          this.togglePopup('autopaySecondStep')
        }
      }

      if (!shouldHandleStripe || !(isP24 || isStripeCard || isIdeal || isStripeSofort || isDigitalPayment)) {
        this.redirect(redirectLink)
      }
    } else if (error) {
      this.setLoading(false)
    }
  }

  @action cancelPS = async () => {
    this.setLoading(true)
    const resp = await this.childApi.cancelOrder(this.root.sellerStore.item.username, this.data.token)
    const { data, success } = resp

    if (success) {
      notify('success', I18n.t('react.shop.order.canceled_successfully'))
      this.data = {
        ...this.data,
        ...data,
      }
    }
    this.setLoading(false)
  }

  @action updateOrder = async (postData) => {
    this.setLoading(true)
    const resp = await this.childApi.updateItem(
      { username: this.root.sellerStore.item.username, token: this.data.token },
      postData
    )
    const { data, success } = resp

    if (success) {
      notify('success', I18n.t('react.shared.notify.success'))
      this.data = {
        ...this.data,
        ...data,
      }
    }
    this.setLoading(false)
  }

  @action continuePS = async () => {
    this.setLoading(true)
    const resp = await this.childApi.continueOrder(this.root.sellerStore.item.username, this.data.token)
    const { data, success } = resp

    if (success) {
      notify('success', I18n.t('react.shop.order.renewed_successfully'))
      this.data = {
        ...this.data,
        ...data,
      }
    }
    this.setLoading(false)
  }

  @action changePSMethod = async (
    stripeClient: Stripe,
    stripeElement?:
      | StripeElements
      | StripeElement
      | StripeP24BankElement
      | StripeIdealBankElement
      | StripeExpressCheckoutElement,
    newPaymentForm?: string,
    elements?: StripeElements
  ) => {
    const { moneyHolder, payer, paymentForm, token, payerData } = this.data || {}

    this.setLoading(true)

    const changeOrderMethodData = {
      ...this.paymethodsData,
    }
    const isCard = (newPaymentForm || paymentForm) === PAYMENT_FORMS.card
    const isSepa = (newPaymentForm || paymentForm) === PAYMENT_FORMS.sepa
    const isMangoPay = moneyHolder.cardProvider === PAYMENT_PROVIDERS.mangoPay

    if (isCard && isMangoPay) {
      const mp3dsBrowserMetaData = getMP3dsBrowserMetaData()

      changeOrderMethodData.transfer = {
        ...changeOrderMethodData.transfer,
        ...mp3dsBrowserMetaData,
      }
    }

    if (this.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.fraudSessionIdentifier,
      }

      changeOrderMethodData.transfer = {
        ...changeOrderMethodData.transfer,
        ...paypalFraudData,
      }
    }

    if (isSepa && stripeElement && 'submit' in stripeElement) {
      const { error: submitError } = await stripeElement.submit()
      if (submitError) {
        handleSepaError(submitError)
        return
      }

      // Create the ConfirmationToken using the details collected by the Payment Element
      const { error, confirmationToken } = await stripeClient.createConfirmationToken({
        elements: stripeElement,
        params: {
          payment_method_data: {
            billing_details: {
              name: `${payerData.userProfileAttributes.firstName} ${payerData.userProfileAttributes.lastName}`,
              email: payerData.userAttributes.email,
              phone: payerData.userProfileAttributes.phone || '',
              address: {
                city: payerData.userProfileAttributes.city,
                country: payerData.userProfileAttributes.countryCode,
                postal_code: payerData.userProfileAttributes.zip,
                state: payerData.userProfileAttributes.state || '',
                line1: payerData.userProfileAttributes.street || '',
                line2: payerData.userProfileAttributes.streetNumber || '',
              },
            },
          },
        },
      })

      if (error) {
        handleSepaError(error)
        notify('error', error.message)
        return
      }

      const token = { confirmationToken: confirmationToken.id }

      changeOrderMethodData.transfer = {
        ...changeOrderMethodData.transfer,
        ...token,
      }
    }

    const resp = await this.childApi.changeOrderMethod(
      this.root.sellerStore.item.username,
      token,
      changeOrderMethodData
    )
    const { data: respData, redirectLink, clientSecret, success } = resp

    if (success) {
      notify('success', I18n.t('react.shop.order.method_changed_success'))

      const shouldHandleStripe = redirectLink && clientSecret && stripeClient
      const isP24 = respData.paymentForm === PAYMENT_FORMS.p24
      const isIdeal = respData.paymentForm === PAYMENT_FORMS.ideal
      const isStripeCard = respData.paymentForm === PAYMENT_FORMS.card
      const isStripeSofort =
        respData.paymentForm === PAYMENT_FORMS.sofort &&
        (this.root.sellerStore.item.sofortProvider === PAYMENT_PROVIDERS.stripe ||
          this.root.sellerStore.item.sofortProvider === PAYMENT_PROVIDERS.elopageConnect)

      if (shouldHandleStripe && isP24) {
        handleP24Transaction(stripeClient, stripeElement as StripeP24BankElement, redirectLink, clientSecret, {
          fullName: payer.fullName,
          email: payer.email,
          tosShowAndAccepted: false,
        })
      }

      if (shouldHandleStripe && isIdeal) {
        handleIdealTransaction(stripeClient, stripeElement as StripeIdealBankElement, redirectLink, clientSecret, {
          fullName: payer.fullName,
          email: payer.email,
        })
      }

      if (shouldHandleStripe && isStripeSofort) {
        handleSofortTransaction(stripeClient, redirectLink, clientSecret, {
          countryCode: this.data.payer.countryCode,
          billingDetails: this.getBillingDetails(),
        })
      }

      if (shouldHandleStripe && isStripeCard) {
        handleStripeCardTransaction(stripeClient, { stripeCard: stripeElement }, clientSecret).then((result) =>
          handleTransactionResponse(
            result,
            () => this.redirect(redirectLink),
            () => this.setLoading(false)
          )
        )
      }

      if (shouldHandleStripe && this.isDigitalPayment) {
        handleStripeDigitalTransaction(
          stripeClient,
          stripeElement as StripeExpressCheckoutElement,
          elements,
          clientSecret,
          redirectLink,
          () => this.setLoading(false)
        )
      }

      if (!shouldHandleStripe || !(isP24 || isStripeCard || isStripeSofort || isIdeal || this.isDigitalPayment)) {
        if (redirectLink) {
          this.popups.changeMethod = false
          this.redirect(redirectLink)
        } else {
          this.popups.changeMethod = false
          this.data = {
            ...this.data,
            ...respData,
          }
        }
        this.setLoading(false)
      }
    } else {
      this.setLoading(false)
    }
  }

  @action changePSPlan = async (
    stripeClient?: Stripe,
    stripeElement?: StripeElement | StripeExpressCheckoutElement | StripeP24BankElement | StripeIdealBankElement,
    elements?: StripeElements,
    showPopup?: () => void
  ) => {
    const { moneyHolder, payer, paymentForm, token } = this.data || {}

    this.setLoading(true)

    const showDigitalPaymentModal = this.isDigitalPayment && stripeClient && stripeElement && this.hasDebtRates
    if (showDigitalPaymentModal) {
      showPopup()
    }

    const chargeOrderData: ChargeOrderData = {
      pricingPlanId: this.selectedPlanId,
    }

    const isCard = paymentForm === PAYMENT_FORMS.card
    const isMangoPay = moneyHolder.cardProvider === PAYMENT_PROVIDERS.mangoPay

    if (isCard && isMangoPay) {
      chargeOrderData.transfer = getMP3dsBrowserMetaData()
    }

    if (this.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.fraudSessionIdentifier,
      }

      chargeOrderData.transfer = {
        ...(chargeOrderData?.transfer || {}),
        ...paypalFraudData,
      }
    }

    const resp = await this.childApi.changeOrderPlan(this.root.sellerStore.item.username, token, chargeOrderData)
    const { data, redirectLink, clientSecret, paymentMethod, success } = resp

    if (success) {
      notify('success', I18n.t('react.shop.order.plan_changed_success'))

      const shouldHandleStripe = redirectLink && clientSecret && stripeClient
      const isP24 = paymentForm === PAYMENT_FORMS.p24
      const isIdeal = paymentForm === PAYMENT_FORMS.ideal
      const isStripeCard = paymentForm === PAYMENT_FORMS.card
      const isStripeSofort =
        paymentForm === PAYMENT_FORMS.sofort &&
        (this.root.sellerStore.item.sofortProvider === PAYMENT_PROVIDERS.stripe ||
          this.root.sellerStore.item.sofortProvider === PAYMENT_PROVIDERS.elopageConnect)

      if (shouldHandleStripe && isP24) {
        handleP24Transaction(stripeClient, stripeElement as StripeP24BankElement, redirectLink, clientSecret, {
          fullName: payer.fullName,
          email: payer.email,
          tosShowAndAccepted: false,
        })
      }

      if (shouldHandleStripe && isIdeal) {
        handleIdealTransaction(stripeClient, stripeElement as StripeIdealBankElement, redirectLink, clientSecret, {
          fullName: payer.fullName,
          email: payer.email,
        })
      }

      if (shouldHandleStripe && isStripeSofort) {
        handleSofortTransaction(stripeClient, redirectLink, clientSecret, {
          countryCode: payer.countryCode,
          billingDetails: this.getBillingDetails(),
        })
      }

      if (shouldHandleStripe && (isStripeCard || this.isDigitalPayment)) {
        if (paymentMethod) {
          if (isStripeCard) {
            handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret).then((result) =>
              handleTransactionResponse(
                result,
                () => this.redirect(redirectLink),
                () => this.setLoading(false)
              )
            )
          }
          if (this.isDigitalPayment) {
            handleStripeDigitalTransaction(
              stripeClient,
              stripeElement as StripeExpressCheckoutElement,
              elements,
              clientSecret,
              redirectLink,
              () => this.setLoading(false)
            )
          }
        } else {
          this.setStripePaymentParams({
            redirectLink,
            clientSecret,
            payerFullName: payer.fullName,
            payerCountryCode: payer.countryCode || payer.country?.code,
            payerEmail: payer.email,
          })
          this.setLoading(false)
          this.togglePopup('autopaySecondStep')
        }
      }

      if (!shouldHandleStripe || !(isP24 || isIdeal || isStripeCard || isStripeSofort || this.isDigitalPayment)) {
        if (redirectLink) {
          this.popups.changePlan = false
          this.redirect(redirectLink)
        } else {
          this.popups.changePlan = false
          this.popups.changePlanAutopay = false
          this.data = {
            ...this.data,
            ...data,
          }
        }
      }
    }

    this.setLoading(false)
  }

  @action resendEmail = (username: string, orderToken: string) => this.childApi.resendEmail(username, orderToken)

  @computed get hasDebtRates() {
    const { nextChargeAmount, nextChargeDate } = this.data || {}
    return nextChargeAmount && nextChargeDate < new Date().toISOString()
  }

  @computed get isDigitalPayment() {
    const { form } = this.paymethods || {}
    return form === PAYMENT_FORMS.applePay || form === PAYMENT_FORMS.googlePay
  }

  //Postsell's functions

  @computed get lastOrder() {
    return this.list[this.list.length - 1] || ({} as Order)
  }

  @computed get firstOrder() {
    return this.list[0] || ({} as Order)
  }

  getBillingDetails = () => {
    const { stripeSofortSepa, elopageConnectSofortSepa } = this.root.sellerStore.item || {}
    const { sellableWithPeriod } = this.data || {}
    const { planPrefs } = sellableWithPeriod || {}

    return planPrefs?.sofortSepa && (stripeSofortSepa || elopageConnectSofortSepa)
      ? {
          billing_details: {
            name: this.data.payer.fullName,
            email: this.data.payer.email,
          },
        }
      : {}
  }

  getSepaStripeElementPayload = (): StripeElementsOptionsMode => {
    const { sepaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    return {
      mode: 'setup',
      paymentMethodCreation: 'manual',
      paymentMethodTypes: ['sepa_debit'],
      currency: this.root.currenciesStore.getKey(this.data?.sellableWithPeriod?.pricingPlan?.currencyId),
      setupFutureUsage: 'off_session',
      ...(sepaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }
  }

  getKlarnaStripeElementPayload = (): StripeElementsOptionsMode => {
    const { klarnaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    const klarnaParams = {
      mode: 'payment',
      paymentMethodTypes: [PAYMENT_FORMS.klarna],
      currency: this.root.currenciesStore.getKey(this.data?.sellableWithPeriod?.pricingPlan?.currencyId),
      amount: convertPriceForStripe(this.stripePaymentParams?.amount || this.data?.nextChargeAmount || 0),
      ...(klarnaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }

    this.kibanaApi.reportKlarnaParams(this.root.sellerStore?.item?.id, {
      ...klarnaParams,
      source: 'orders',
    })

    return klarnaParams as StripeElementsOptionsMode
  }

  getTwintStripeElementPayload = (): StripeElementsOptionsMode => ({
    mode: 'payment',
    paymentMethodTypes: [PAYMENT_FORMS.twint],
    currency: this.root.currenciesStore.getKey(this.data?.sellableWithPeriod?.pricingPlan?.currencyId),
    amount: convertPriceForStripe(this.stripePaymentParams?.amount || this.data?.nextChargeAmount || 0),
  })

  getCurrency = () => this.root.currenciesStore.getKey(this.data?.sellableWithPeriod?.pricingPlan?.currencyId)

  preparePostsell = async (params?: { funnel: string }) => {
    const { error } = this.item
    const {
      item: { username },
    } = this.root.sellerStore
    const { paymentForm, token, funnelId } = this.lastOrder

    const funnelOn = params?.funnel === 'on'
    const rootOrderState = this.firstOrder.paymentState
    const orderPaymentIsSuccessful = SUCCESSFUL_ORDER_STATES.find((state) => state === rootOrderState)
    const showFunnel = funnelOn && paymentForm !== 'free' && !error && !!funnelId && orderPaymentIsSuccessful

    if (showFunnel) {
      const resp = await this.root.funnelsStore.fetchFunnel(username, token)

      return resp.data
    } else {
      this.root.funnelsStore.toggleLoading(false)
    }
  }

  onRejectPostsell = async () => {
    const { token } = this.lastOrder
    const {
      item: { username },
    } = this.root.sellerStore
    const { currentFunnelNode: node, rejectPostsell } = this.root.funnelsStore

    const resp = await rejectPostsell(username, token, { funnelNodeId: node?.id })

    if (this.root.funnelsStore.currentFunnelNode) {
      await this.fireFunnelPageTrackings(resp.data)
    } else {
      await this.fireSuccessPageTrackings()
    }

    window.scrollTo(0, 0)
  }

  onSubmitPostsell = async (data) => {
    const { token } = this.lastOrder
    const {
      item: { username },
    } = this.root.sellerStore
    const { currentFunnelNode, acceptPostsell, setLoading, setNextFunnelNode } = this.root.funnelsStore
    const {
      moneyHolder,
      moneyHolder: {
        cardProvider,
        sofortProvider,
        sepaProvider,
        applePayProvider,
        googlePayProvider,
        p24Provider,
        idealProvider,
        klarnaProvider,
        twintProvider,
      } = {},
      paymentForm,
    } = this.data
    const isCard = paymentForm === PAYMENT_FORMS.card
    const isMangoPay = this.data.moneyHolder.cardProvider === PAYMENT_PROVIDERS.mangoPay

    const acceptPostsellParams = {
      funnelNodeId: currentFunnelNode?.id,
      ...data,
      ...(this.fraudSessionIdentifier && { paypalFraudToken: this.fraudSessionIdentifier }),
      ...(isCard && isMangoPay ? getMP3dsBrowserMetaData() : {}),
    }

    const isP24 = paymentForm === PAYMENT_FORMS.p24
    const isIdeal = paymentForm === PAYMENT_FORMS.ideal
    const isStripeSofort =
      paymentForm === PAYMENT_FORMS.sofort &&
      (sofortProvider === PAYMENT_METHODS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)
    const isStripeCard =
      paymentForm === PAYMENT_FORMS.card &&
      (cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect)
    const isDigitalPayment = paymentForm === PAYMENT_FORMS.applePay || paymentForm === PAYMENT_FORMS.googlePay
    const skipFunnelRedirect = isP24 || isIdeal || isStripeCard || isStripeSofort || isDigitalPayment

    const resp = (await acceptPostsell(username, token, acceptPostsellParams, skipFunnelRedirect)) || {
      success: false,
      data: null,
    }
    const { success } = resp
    const { redirectLink, clientSecret, paymentMethod } = resp.data || {}

    if (success) {
      const providers = {
        cardProvider,
        sofortProvider,
        sepaProvider,
        applePayProvider,
        googlePayProvider,
        p24Provider,
        idealProvider,
        klarnaProvider,
        twintProvider,
      }
      const pubKey = (moneyHolder || {})[getPubKey(paymentForm, providers)]

      if (redirectLink && (isP24 || isIdeal) && clientSecret) {
        this.togglePopup('postsellPayment')
        this.setStripePaymentParams({
          redirectLink,
          clientSecret,
          payerFullName: this.data.payer.fullName,
          payerCountryCode: this.data.payer.countryCode || this.data.payer.country?.code,
          payerEmail: this.data.payer.email,
        })
        setLoading(false)
      } else if (clientSecret && paymentMethod && (isStripeCard || isDigitalPayment)) {
        handleStripeCardRecuringPayment(
          clientSecret,
          paymentMethod,
          pubKey,
          () => {
            if (redirectLink) {
              this.redirect(redirectLink)
            } else {
              setNextFunnelNode(username, token, YES)
            }
          },
          () => setLoading(false)
        )
      } else if (clientSecret && redirectLink && isStripeSofort) {
        handleStripeSofortRecuringPayment(
          clientSecret,
          pubKey,
          redirectLink,
          {
            countryCode: this.data.payer.countryCode || this.data.payer.country?.code,
            billingDetails: this.getBillingDetails(),
          },
          () => setNextFunnelNode(username, token, YES),
          () => setLoading(false)
        )
      } else if (redirectLink) {
        this.redirect(redirectLink)
      } else {
        await this.fetchItem(token, { username })
        await this.fetchList({ token, username })

        if (this.root.funnelsStore.currentFunnelNode) {
          await this.fireFunnelPageTrackings(this.root.funnelsStore.funnel)
        } else {
          await this.fireSuccessPageTrackings()
        }

        setLoading(false)
      }
    }

    window.scrollTo(0, 0)
  }

  fireFunnelPageTrackings = async (funnel) => {
    const funnelNode = this.root.funnelsStore.currentFunnelNode || funnel || {}

    this.root.trackingCodesStore.setTrackEventData('funnel_page', {
      username: this.root.sellerStore.item.username,
      consentForm: this.root.sellerStore.item.consentForm,
      funnelId: funnel?.id,
      funnelName: funnel?.name,
      funnelPageId: funnelNode?.id,
      funnelPageName: funnelNode?.name,
    })

    await this.root.trackingCodesStore.trackEvent()
  }

  fireSuccessPageTrackings = async () => {
    for (const item of this.list) {
      if (!item.tracked) {
        const { currencyKey } = item
        this.root.trackingCodesStore.setTrackEventData('success_page', {
          productId: item.product.id,
          sellerId: this.root.sellerStore.item.id,
          username: this.root.sellerStore.item.username,
          consentForm: this.root.sellerStore.item.consentForm,
          transactionId:
            (arrayLast<{ idForSeller: number }>(item.transfers) || {}).idForSeller ||
            (arrayLast<Invoice>(item.invoices) || {}).number,
          orderId: item.id,
          periodType: item.periodType,
          orderForm: item.paymentForm,
          revenue:
            (arrayLast<Transfer>(item.transfers) || {}).total || (arrayLast<Invoice>(item.invoices) || {}).total || 0,
          funnelId: item.funnelId,
          funnelName: item.funnelName,
          funnelNodeId: item.funnelNodeId,
          productIds: item.sellables.map((p) => p.product.id),
          productNames: item.sellables.map((p) => p.product.name),
          currency: currencyKey || DEFAULT_CURRENCY,
        })

        await this.root.trackingCodesStore.trackEvent()
      }
    }
  }

  //End Postsell's functions

  @computed get paymethodsData(): {
    transfer: { confirmationToken?: string; creditCard: string | { num: string }; form: string; iban: string }
  } {
    const { creditCard: paymethodCard, form, iban } = this.paymethods || {}
    const { moneyHolder, id } = this.data
    const {
      item: { cardProvider },
    } = this.root.sellerStore
    const usedMainProvider = id ? moneyHolder?.cardProvider || cardProvider : cardProvider

    const creditCard = usedMainProvider === PAYMENT_PROVIDERS.lemonWay ? { num: CARD_NUMBER } : paymethodCard

    return {
      transfer: {
        creditCard,
        form,
        iban,
      },
    }
  }

  markPsAsTracked = (username: string, orderId: number) => this.childApi.markPsAsTracked(username, orderId)

  @override
  hydrate(key, data) {
    if (key === 'item') {
      this.item = data
    } else if (key === 'list') {
      this.list = data
    } else if (key === 'data') {
      this.data = data
    } else if (key === 'loading') {
      this.loading = data
    } else if (key === 'ticketUpgradeData') {
      this.ticketUpgradeData = data
    }
  }
}
