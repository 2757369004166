export const STRIPE_ELEMENTS_MODE = {
  payment: 'payment',
  setup: 'setup',
  subscription: 'subscription',
} as const

export const SETUP_FUTURE_USAGE = {
  offSession: 'off_session',
  onSession: 'on_session',
} as const

export const CAPTURE_METHOD = {
  automatic: 'automatic',
  automaticAsync: 'automatic_async',
  manual: 'manual',
} as const

export const DEFAULT_STRIPE_AMOUNT = 100
