import React, { useEffect } from 'react'

import {
  createStripeClient,
  createStripeElements,
  injectStripeScript,
  isStripeInjected,
} from '@elo-kit/utils/stripe.utils'
import type { StripeMessageParams } from 'shop/types/stripe'

import { KLARNA_COUNTRIES } from 'constants/paymentSettingShared.constants'

import './klarna-message.scss'

interface Props {
  targetId: string
  pubKey: string
  messageOptions: {
    amount: number
    currency: string
    countryCode: string
  }
  theme: Record<string, any>
}
export const KlarnaMessage = ({ targetId, pubKey, messageOptions, theme = {} }: Props) => {
  const initStripe = (onLoad: () => void) => {
    if (!isStripeInjected()) {
      injectStripeScript(onLoad)
    } else {
      onLoad()
    }
  }

  const showMessage = () => {
    if (targetId && KLARNA_COUNTRIES.includes(messageOptions.countryCode)) {
      const stripeClient = createStripeClient(pubKey)
      const elements = createStripeElements(stripeClient, { appearance: theme } as StripeMessageParams)
      //@ts-ignore
      const paymentMessageElement = elements.create('paymentMethodMessaging', {
        paymentMethodTypes: ['klarna'],
        ...messageOptions,
      })
      if (paymentMessageElement) {
        paymentMessageElement.mount(`#${targetId}`)
      }
    }
  }

  useEffect(() => {
    initStripe(showMessage)
  }, [pubKey])

  return <></>
}
