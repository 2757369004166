import { ELO_SLIDER_ARROWS_Z_INDEX } from '../../constants/layers.constants'

// TODO: update with using createUseStyles
const eloSliderStyles = (theme = {}) => {
  const height = theme.height * theme.activeElementScale + 20

  return {
    eloSliderContainer: {
      position: 'relative',
      containerType: 'inline-size',
      containerName: 'elo-slider-container',
      width: theme.width * (theme.modifier || 1) + 70,
      height,
      padding: '10px 35px',
      overflow: 'hidden',
      justifyContent: 'center',
      '& .fas': {
        cursor: 'pointer',
        fontSize: theme.arrowFontSize ? `${theme.arrowFontSize}px !important` : '20px',
        color: theme.arrowsColor || '#FFB000',
        zIndex: 1,
        '&.elo-slider-arrow__with-zindex': {
          zIndex: ELO_SLIDER_ARROWS_Z_INDEX,
        },
        '&:hover': {
          opacity: 0.8,
        },
      },
      '& .fa-chevron-right': {
        position: 'absolute',
        top: '50%',
        right: 0,
      },
      '& .fa-chevron-left': {
        position: 'absolute',
        top: '50%',
        left: 0,
      },
      '@container (max-width: 360px)': {
        width: theme.width + 70,
      },
      '@container (max-width: 768px)': {
        width: theme.width + 70,
      },
    },
    eloSliderElement: {
      position: 'absolute',
      visibility: 'visible',
      top: (theme.height * theme.activeElementScale - theme.height) / 2,
      opacity: 0,
      transition: theme.modifier === 1 ? 'none' : 'transform 1s, opacity 0.1s',
    },
    '@container (max-width: 360px)': {
      top: (theme.height - theme.height) / 2,
      transition: 'none !important',
    },
    '@container (max-width: 768px)': {
      top: (theme.height - theme.height) / 2,
      transition: 'none !important',
    },
    rightHidden: {
      transform: `translate(${theme.width * theme.modifier + 30}px)`,
      '@container (max-width: 360px)': {
        transform: `translate(${theme.width + 30}px)`,
      },
      '@container (max-width: 768px)': {
        transform: `translate(${theme.width + 30}px)`,
      },
    },

    leftHidden: {
      transform: `translate(-${theme.width + 30}px)`,
      '@container (max-width: 360px)': {
        transform: `translate(-${theme.width + 30}px)`,
      },
      '@container (max-width: 768px)': {
        transform: `translate(-${theme.width + 30}px)`,
      },
    },
    activeElement: {
      visibility: 'visible',
      zIndex: 1,
      opacity: 1,
      transform: `translate(${(theme.width * theme.modifier) / 2 - theme.width / 2}px) scale(${
        theme.activeElementScale
      })`,
      transition: `transform 1s, opacity ${theme.modifier === 1 ? 1 : 0.1}s`,
      '@container (max-width: 360px)': {
        transition: `transform 1s, opacity 1s`,
        transform: `translate(${theme.width / 2 - theme.width / 2}px) scale(1)`,
      },
      '@container (max-width: 768px)': {
        transition: `transform 1s, opacity 1s`,
        transform: `translate(${theme.width / 2 - theme.width / 2}px) scale(1)`,
      },
    },
    active: {
      color: 'white',
    },
    rightNext: {
      transform: `translate(${theme.modifier === 1 ? theme.width : theme.width * theme.modifier - theme.width}px)`,
      opacity: 0.5,
    },
    '@container (max-width: 360px)': {
      transform: `translate(${theme.width}px)`,
    },
    '@container (max-width: 768px)': {
      transform: `translate(${theme.width}px)`,
    },
    leftNext: {
      transform: `translate(${theme.modifier === 1 ? -(theme.width + 30) : 0}px)`,
      opacity: 0.5,
    },
    '@container (max-width: 360px)': {
      transform: `translate(-${theme.width + 30}px)`,
    },
    '@container (max-width: 768px)': {
      transform: `translate(-${theme.width + 30}px)`,
    },
    hiddenElement: {
      opacity: 0,
      visibility: 'hidden',
    },
  }
}

export default eloSliderStyles
