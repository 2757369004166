import { TrackingUserEvent } from '../stores/trackingUserEvents.store'

const LEGACY_BASE_URL = '/common/view_logs'
const BASE_URL = '/common/tracking_user_events'

export const createUserEventsApi = ({ POST }) => ({
  track: (data: TrackingUserEvent) => POST(BASE_URL, data),
})

export const createLegacyUserEventsApi = ({ POST }) => ({
  track: (data) => POST(LEGACY_BASE_URL, data, { skipErrorNotific: true }),
})

export type TrackingUserEventsApi = ReturnType<typeof createUserEventsApi>
export type LegacyTrackingUserEventsApi = ReturnType<typeof createLegacyUserEventsApi>
