import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { buttonAnimations } from '@elo-kit/utils/animations.utils'

import { PRODUCTS_LIST, BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'
import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlue, eloBlack, eloWhite } = COLORS

// TODO: update with using createUseStyles
const productListStyles = (theme) => ({
  list: {
    height: '100%',
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    maxWidth: '1140px',
  },

  item: {
    display: 'flex',
    padding: '30px 15px',
    borderBottom: '1px solid #eaeaea99',

    '@media (max-width: 991px)': {
      flexWrap: 'wrap',
    },
    '@container (max-width: 991px)': {
      flexWrap: 'wrap',
    },
  },

  listPriceBlock: {
    top: 15,
    minWidth: 80,
    width: 'auto',
    padding: 5,
    right: -15,
    height: 30,
    borderRadius: 0,

    '& > span': {
      fontSize: `${theme[PRODUCTS_LIST.priceSize] || 15}px`,
    },
  },

  listBackgroundImageContainer: {
    height: 120,
    borderRadius: 6,
    backgroundColor: '#efedef',
    color: eloWhite,
    fontSize: 50,
    width: 120,
    minWidth: 120,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 6px 20px 0 #00000026',
    marginRight: 40,
    position: 'relative',
    cursor: 'pointer',

    '& i': {
      margin: 'auto',
    },

    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 6px 20px 0 #00000050',
      '& i': {
        color: eloWhite,
      },
    },

    '@media (max-width: 991px)': {
      marginRight: 25,
      width: 120,
      minWidth: 120,
      height: 120,
    },
    '@container (max-width: 991px)': {
      marginRight: 25,
      width: 120,
      minWidth: 120,
      height: 120,
    },
  },

  productCover: {
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'center',
    borderRadius: 6,
    height: 120,
  },

  productTitle: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    marginBottom: 15,
    width: '100%',
    fontSize: theme[PRODUCTS_LIST.productSize] ? `${theme[PRODUCTS_LIST.productSize]}px` : '18px',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.productFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[PRODUCTS_LIST.productStyle]),
    color: theme[PRODUCTS_LIST.productColor] || eloBlack,
    lineHeight: 1.2,

    '@media (max-width: 991px)': {
      marginBottom: 10,
    },

    '@container (max-width: 991px)': {
      marginBottom: 10,
    },

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.productSize] > 26 ? 26 : theme[PRODUCTS_LIST.productSize],
    },

    '@container (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.productSize] > 26 ? 26 : theme[PRODUCTS_LIST.productSize],
    },
  },

  price: {
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.priceFont]) || 'Montserrat Medium',
    fontSize: `${theme[PRODUCTS_LIST.priceSize] || 20}px`,
    ...getFontStyles(theme[PRODUCTS_LIST.priceStyle || 'normal']),
    color: theme[PRODUCTS_LIST.priceColor] || eloWhite,

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.priceSize] > 40 ? 40 : theme[PRODUCTS_LIST.priceSize],
    },

    '@container (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.priceSize] > 40 ? 40 : theme[PRODUCTS_LIST.priceSize],
    },
  },

  listProductTitle: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    lineHeight: 1.5,
    marginBottom: 10,
  },

  listProductDetails: {
    width: '100%',
    marginRight: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '&:hover': {
      textDecoration: 'none',
      opacity: 0.9,
      color: theme[PRODUCTS_LIST.productColor] || eloBlack,
      cursor: 'pointer',
    },

    '@media (max-width: 991px)': {
      marginRight: 0,
    },
    '@container (max-width: 991px)': {
      marginRight: 0,
    },
  },

  payInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 230,
    maxWidth: 400,
    flexShrink: 0,
    justifyContent: 'center',

    '@media (max-width: 991px)': {
      width: '100%',
    },
    '@container (max-width: 991px)': {
      width: '100%',
    },
  },

  listPriceContent: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  description: {
    fontSize: theme[PRODUCTS_LIST.descriptionSize] ? `${theme[PRODUCTS_LIST.descriptionSize]}px` : '14px',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.descriptionFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[PRODUCTS_LIST.descriptionStyle]),
    color: theme[PRODUCTS_LIST.descriptionColor] || eloBlack,
    wordBreak: 'break-word',
    display: 'block',

    '@media (max-width: 991px)': {
      display: 'none',
    },
    '@container (max-width: 991px)': {
      display: 'none',
    },
  },

  currency: {
    fontSize: theme[PRODUCTS_LIST.currencySize] ? `${theme[PRODUCTS_LIST.currencySize]}px` : '30px',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.currencyFont]) || 'Montserrat Medium',
    ...getFontStyles(theme[PRODUCTS_LIST.currencyStyle] || 'normal'),
    color: theme[PRODUCTS_LIST.currencyColor] || '#808080',

    '@media (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.currencySize] > 40 ? 40 : theme[PRODUCTS_LIST.currencySize],
    },
    '@container (max-width: 576px)': {
      fontSize: theme[PRODUCTS_LIST.currencySize] > 40 ? 40 : theme[PRODUCTS_LIST.currencySize],
    },
  },

  productDetailsContainer: {
    display: 'flex',
    width: '100%',
  },

  listPriceContainer: {
    display: 'block',
    textAlign: 'center',
    lineHeight: 1.2,
    '@media (max-width: 991px)': {
      display: 'none',
    },
    '@container (max-width: 991px)': {
      display: 'none',
    },
  },

  mobileListPriceContainer: {
    display: 'none',
    '@media (max-width: 991px)': {
      display: 'block',
      lineHeight: 1,
    },
    '@container (max-width: 991px)': {
      display: 'block',
      lineHeight: 1,
    },
  },

  oldPriceLine: {
    position: 'absolute',
    backgroundColor: '#ff4747',
    margin: 0,
    top: 'calc(50% - 2px)',
    right: 0,
    left: 0,
  },

  oldPrice: {
    display: 'flex',

    '& div:first-child': {
      position: 'relative',
      padding: '0 3px',
      lineHeight: 0,

      '& span': {
        lineHeight: 1,
        fontSize: theme[PRODUCTS_LIST.oldPriceSize] ? `${theme[PRODUCTS_LIST.oldPriceSize]}px` : '14px',
        fontFamily: cleanupFonts(theme[PRODUCTS_LIST.oldPriceFont]) || 'Montserrat Reg',
        ...getFontStyles(theme[PRODUCTS_LIST.oldPriceStyle]),
        color: theme[PRODUCTS_LIST.oldPriceColor] || '#9e9e9e',
      },
    },
  },

  oldPriceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',

    '@media (max-width: 991px)': {
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
    '@container (max-width: 991px)': {
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
  },

  button: {
    display: 'inline-block',
    padding: '6px 12px',
    textAlign: 'center',
    verticalAlign: 'middle',
    background: [
      theme.buttonViewStyle === BUTTON_STYLE.outlined ? 'none' : theme[PRODUCTS_LIST.btnColor] || eloBlue,
      '!important',
    ],
    border: `2px solid ${theme[PRODUCTS_LIST.btnColor] || eloBlue}`,
    borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
    color: [theme[PRODUCTS_LIST.btnTextColor] || eloWhite, '!important'],
    boxShadow: 'none',
    fontFamily: cleanupFonts(theme[PRODUCTS_LIST.buttonFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[PRODUCTS_LIST.buttonStyle]),
    fontSize: `${theme[PRODUCTS_LIST.buttonSize] || 16}px`,
    wordBreak: 'break-word',
    width: '80%',

    '&:active': {
      opacity: 0.6,
    },
  },

  ...buttonAnimations(theme),

  listButton: {
    width: '100%',
    minHeight: 43,
    marginTop: 5,

    '@media (max-width: 991px)': {
      maxWidth: 335,
      marginTop: 20,
    },
    '@container (max-width: 991px)': {
      maxWidth: 335,
      marginTop: 20,
    },
  },
  controlsContainer: {
    maxWidth: '1140px',
    width: '100%',
    margin: '0 auto 0',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',

    '& .builder-field': {
      marginBottom: 0,
    },

    '@media (max-width: 576px)': {
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& .builder-field': {
        marginBottom: 20,
      },
      '& .builder-field:last-child': {
        marginBottom: 0,
      },
    },
    '@container (max-width: 576px)': {
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& .builder-field': {
        marginBottom: 20,
      },
    },
  },
})

export default productListStyles
