import { action, makeObservable, observable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { Stripe, StripeElement } from 'shop/types/stripe'

import SharedStore from 'shared/stores/shared.store'

export class PurchaseMethodsStore extends SharedStore<any> {
  storeName = 'PurchaseMethodsStore'
  root: ShopRootStore

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    makeObservable(this)
  }

  @observable stripeClient = null
  @observable stripeP24 = null
  @observable stripeIdeal = null
  @observable stripeCard = null
  @observable stripeAuthentificationLoading = false
  @observable paymethods: Record<string, never> = {}
  @observable fraudSessionIdentifier = null
  @observable stripeCardValid = false
  @observable shouldResetIntent = false
  @observable buyBtnDisabled = false
  @observable stripeElements = null
  @observable googleAvailability = {
    googlePay: false,
  }
  @observable appleAvailability = {
    applePay: false,
  }

  @action setStripeElements = (value: StripeElement | string) => (this.stripeElements = value)
  @action setStripeCardValidity = (isValid: boolean) => (this.stripeCardValid = isValid)
  @action updatePaymethodsData = (paymethods) => (this.paymethods = paymethods)
  @action setStripeP24 = (value: StripeElement | string) => (this.stripeP24 = value)
  @action setStripeIdeal = (value: StripeElement | string) => (this.stripeIdeal = value)
  @action setStripeCard = (value) => (this.stripeCard = value)
  @action setStripeClient = (value: Stripe) => (this.stripeClient = value)
  @action stripeAuthentificationLoadingToggle = (value) => (this.stripeAuthentificationLoading = value)
  @action setFraudSessionIdentifier = (value) => (this.fraudSessionIdentifier = value)
  @action setBuyBtnDisabling = (value: boolean) => (this.buyBtnDisabled = value)
  @action setGoogleAvailability = (resp) => (this.googleAvailability = resp)
  @action setAppleAvailability = (resp) => (this.appleAvailability = resp)
}
